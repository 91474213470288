import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import LandingPage from './components/LandingPage';
import Blog from './components/Blog';
import Navbar from './components/NavBar/Navbar';
import Signup from './components/Auth/Signup/Signup';
import 'react-toastify/dist/ReactToastify.css';
import Product from './components/Products/Product';
import Products from './components/Products/Products';
function App() {
  return (
    <div className="App">
    <BrowserRouter>
    
    
      <Navbar/>
      <Routes>
      <Route path='/' element={<LandingPage/>}/>
      <Route path='/blog' element={ <Blog/>}/>
      <Route path='/product' element={ <Product/>}/>
      <Route path='/products' element={ <Products/>}/>

      <Route path='/auth/signup' element={ <Signup/>}/>

      </Routes>
      
    </BrowserRouter>
      </div>
  );
}

export default App;
