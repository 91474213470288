

import React, {useState} from 'react'; 
import 'bootstrap/dist/css/bootstrap.css'; 
import Form from 'react-bootstrap/Form'; 
import Button from 'react-bootstrap/Button'; 
import { ToastContainer, toast } from 'react-toastify';

function Signup() { 
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLogged, setLogged] = useState(false);

    

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(email);
       
        toast.success("Hello World")
        setLogged(!isLogged)
    }

return ( 
	<div style={{ display: 'block', 
				width: 700, 
				padding: 30 }}> 
	<h4>React-Bootstrap Form Component</h4> 
	<Form> 
	 
		<Form.Group> 
		<Form.Label>Enter your email address:</Form.Label> 
		<Form.Control 
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
		placeholder="Enter your your email address"
        /> 
		</Form.Group> 
		<Form.Group> 
		<Form.Label>Enter your Password:</Form.Label> 
		<Form.Control 
        type="password" 
        value={password}         
        onChange={(e) => setPassword(e.target.value)}

        placeholder="Enter your Password" /> 
		</Form.Group> 
		<Button variant="primary" type="submit" onClick={handleSubmit}> 
		Submit
		</Button> 
	</Form> 
    {isLogged ? <h1>Welcome</h1> : <h1>Signup</h1>}
    <ToastContainer/>
	</div> 
); 
}
export default Signup