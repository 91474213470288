import React from 'react'

function LandingPage() {
  return (
    <div>
        LandingPage
        <h1>Landing Page Header</h1>
    </div>
  )
}

export default LandingPage