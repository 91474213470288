import React, { useEffect, useState } from 'react'
import axios  from 'axios';

function Product() {
  const [product, setProduct] = useState([]);

  const handleFetch =  async () =>{
    try {
      await axios.get('https://fakestoreapi.com/products/1').then((response) =>{
        setProduct(response.data);
      })
      
      
      
     } catch (error) {
       console.error("error fetching data", error);
     } 
      
  }
    useEffect(() =>{
      handleFetch()
    })
  return (
    <div className='product-component '>
      <img src={product.image} alt={product.title} className='image'/>
      <div  >Title {product.title} </div>
      <div >Description {product.description} </div>
      <div >Price {product.price} </div>
      <div >Category {product.category} </div>

    </div>
  )
}

export default Product