import React from 'react'
import "./Navbar.css"
import { Link } from 'react-router-dom'
function Navbar() {
  return (
    <div className='navbar-component'>
        <div className='logo'>Logo</div>
        <ul className='nav-links'>
            <Link to='/'>Home</Link>
            <Link to='/aboutus'>About us</Link>
            <Link to='/products'>Products</Link>
            <Link to='/product'>product</Link>

            <Link to ='/Blog'>Blog</Link>

        </ul>
    </div>
  )
}

export default Navbar