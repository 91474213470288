import React, { useEffect, useState } from 'react'
import axios  from 'axios';

function Products() {
  const [products, setProducts] = useState([]);

  const handleFetch =  async () =>{
    try {
      await axios.get('https://fakestoreapi.com/products').then((response) =>{
        setProducts(response.data);
      })
      
      
      
     } catch (error) {
       console.error("error fetching data", error);
     } 
      
  }
    useEffect(() =>{
      handleFetch()
    }, [])
  return (
    <div className='product-component '>
      {products.map((product) => (
        <div key={product.id} className='product-item'> 
            <img src={product.image} alt={product.title} className='image'/>
            <div  >Title {product.title} </div>
            <div >Description {product.description} </div>
            <div >Price {product.price} </div>
            <div >Category {product.category} </div>
            <button>Add to Cart</button>
        </div>
      ))

      }

    </div>
  )
}

export default Products