import React from 'react'
import './Blog.css';

function Blog() {
  return (
    <div className='blog-component'>
        <h1>Blog</h1>
        
    </div>
  )
}

export default Blog